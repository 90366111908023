import React from 'react';
import { WithEmbroidery } from 'ui/component/embroidery-configurator';
import { getProductAttribute } from 'ui/util/get-product-attribute';
import { CommitButtonStyled } from './commit-button-styled';
import { EmbroideryLoadingImage } from './embroidery-loading-image';

export const CommitButton = ({
    enabled,
    isEmbroidered,
    isOpenEmbroiderer,
    config,
    isQuickView,
    handleExit,
    isEmbroideryLockdown,
    isSaveFailed,
    setIsSaveFailed,
    liquidPixelBaseConfig,
    bundleItemPriceData,
}) => {
    const isInStockStatus = enabled && config.simpleProduct.inStock;
    const isDiscontinued = getProductAttribute(
        config.simpleProduct, 'discontinued_product',
    ) === 'Yes' && config.simpleProduct.quantity <= 0;
    const enableButton = !isDiscontinued && isInStockStatus;

    if (isEmbroidered) {
        return (
            <WithEmbroidery
                selections={config.selections}
                configItemId={config.configItemId}
                quantity={config.quantity.current}
                quantityState={config.quantity}
                simpleProduct={config.simpleProduct}
                configurableProduct={config.product}
                afterSave={() => { if (handleExit) handleExit(); }}
                fallback={<EmbroideryLoadingImage isQuickView={isQuickView} />}
                isQuickView={isQuickView}
                isEmbroideryLockdown={isEmbroideryLockdown}
                isSaveFailed={isSaveFailed}
                setIsSaveFailed={setIsSaveFailed}
                liquidPixelBaseConfig={liquidPixelBaseConfig}
                bundleItemPriceData={bundleItemPriceData}
            >
                {embroidery => (
                    <CommitButtonStyled
                        enabled={enableButton}
                        config={config}
                        isQuickView={isQuickView}
                        commitAction={() => embroidery.save()}
                    />
                )}
            </WithEmbroidery>
        );
    }
    if (!isOpenEmbroiderer) {
        return (
            <CommitButtonStyled
                enabled={enableButton}
                config={config}
                isQuickView={isQuickView}
                commitAction={config.handleCommit}
            />
        );
    }
    return null;
};
