import React, { useEffect, useState } from 'react';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { getOptionValue } from 'ui/util/get-option-value';
import { ProductStockAlert } from 'ui/component/product-configurator/product-info/product-stock-alert';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useLocation } from '@silkpwa/module/router/use-location';
import { AttributeOptions } from './attribute-options';
import { SizeGuide } from './size-guide';
import styles from './style.css';

import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;

const getSelected = (config, id) => getOptionValue(
    x => x.id === id,
)(config.selections, config.product);

const getLabel = (attribute, config) => {
    const selected = getSelected(config, attribute.id);
    if (selected) {
        return `${attribute.label}: ${selected}`;
    }
    return attribute.label;
};

export const ConfigAttribute = ({ attribute, isQuickView, config }) => {
    const t = usePhraseTranslater();
    const storeConfig: SilkRestappDataConfigInfoInterface = useConfig();
    const location = useLocation();
    const [triggered, setTriggered] = useState(false);
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const productId = searchParams.get('productId');

        if (triggered || !productId) {
            return;
        }
        const simpleProductOption = config.simpleProduct.index.find(
            option => option.productId.toString() === productId,
        );
        if (simpleProductOption) {
            simpleProductOption.selections.forEach((selection) => {
                const matchingAttribute = config.attributes.find(attr => attr.id === selection.attribute);
                const matchingOption = matchingAttribute?.options.find(opt => opt.id === selection.value);

                if (matchingOption) {
                    matchingOption.handleClick();
                }
            });

            setTriggered(true);
        }
    }, [location.search, config.simpleProduct, config.attributes, triggered]);

    return (
        <div
            className={classes({
                [styles.wrap]: true,
                [styles.quickview]: isQuickView,
                [styles.validate]: (config.validation.current) && (!getSelected(config, attribute.id)),
            })}
        >
            {attribute.label === 'Size' && storeConfig.size_disclaimer !== '' && (
                <div
                    className={styles.disclaimer}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: storeConfig.size_disclaimer }}
                />
            )}
            <div
                className={classes({
                    [styles.label]: true,
                    [styles.quickview]: isQuickView,
                })}
            >
                {t('Select')}
                {' '}
                {getLabel(attribute, config)}
            </div>

            <AttributeOptions
                attribute={attribute}
            />

            {attribute.label === 'Size' && (
                <SizeGuide product={config.product} />
            )}
            {(attribute.label === 'Size' || config.attributes.length === 1) && (
                <ProductStockAlert product={config.simpleProduct} />
            )}
        </div>
    );
};
