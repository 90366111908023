import { gql, TypedDocumentNode } from '@apollo/client';

export interface ISubscribeEmailToNewsletterInput {
    email: string;
}

export interface ISubscribeEmailToNewsletterOutput {
    subscribeEmailToNewsletter: {
        status: string;
    };
}

export const GET_CUSTOMER: TypedDocumentNode = gql`
    query GET_CUSTOMER {
        customer {
            firstname
            lastname
            suffix
            email
            addresses {
                id
                firstname
                lastname
                company
                street
                city
                region {
                    region_code
                    region
                }
                postcode
                country_code
                telephone
            }
            default_billing
            default_shipping
            portal {
                id
                code
            }
            cw_po_number
            store_credit {
                enabled
                current_balance {
                    currency
                    value
                }
            }
        }
    }
`;

export const IS_EMAIL_AVAILABLE: TypedDocumentNode = gql`
    query IS_EMAIL_AVAILABLE(
        $email: String!
    ){
        isEmailAvailable(email: $email) {
            is_email_available
        }
    }
`;

export const SUBSCRIBE_EMAIL_TO_NEWSLETTER:
TypedDocumentNode<ISubscribeEmailToNewsletterOutput, ISubscribeEmailToNewsletterInput> = gql`
    mutation SUBSCRIBE_EMAIL_TO_NEWSLETTER ($email: String!) {
        subscribeEmailToNewsletter(email: $email) {
            status
        }
    }
`;

export const REQUEST_RESET_PASSWORD: TypedDocumentNode = gql`
    mutation requestPasswordResetEmail($email: String!){
        requestPasswordResetEmail(email: $email)
    }
`;

export const SEND_CUSTOMER_LOGIN_LINK: TypedDocumentNode = gql`
    mutation sendCustomerLoginLink($email: String!, $redirect_path: String, $cart_id: String){
        sendCustomerLoginLink(email: $email, redirect_path: $redirect_path, cart_id: $cart_id)
    }
`;
