/* eslint-disable class-methods-use-this */
import { IProductInfo } from 'ui/component/embroidery-configurator/connect-api';
import intersection from 'lodash/intersection';
import {
    IEmbroideryOption, IFlagsOption, ILogosOption,
    IPlacementOption, ITextOption, IColorOption,
    LogoValueObject,
} from 'ui/component/embroidery-configurator/api/embroidery-interfaces';
import { getPortalOverrideCategoryId } from 'ui/util/override-categories/id-param-resolver';
import { getProductTypeBundle } from 'ui/util/get-product-type';
import { getSourceMapping } from './util';
import { ITransformerData, LogoDetailResponseData, LogoResponseData } from '../embroidery-repository';

import SilkChefworksEmbroideryDataEmbroideryInterface =
    Magento.Definitions.SilkChefworksEmbroideryDataEmbroideryInterface;
import SilkRestappDataCartCartItemInterface =
    Magento.Definitions.SilkRestappDataCartCartItemInterface;
import SilkChefworksEmbroideryDataDefaultLogoEmbroideryInterface =
    Magento.Definitions.SilkChefworksEmbroideryDataDefaultLogoEmbroideryInterface;
import IPRuleLogoEmb = Magento.Definitions.ChefworksPersonalizationRulesDataPersonalizationRuleLogoEmbInterface;
import SilkChefworksEmbroideryDataPlacementColorExclusionsInterface =
    Magento.Definitions.SilkChefworksEmbroideryDataPlacementColorExclusionsInterface;

export class Transformer {
    constructor(private transformers, private skuTypes) {
    }

    private transformFromApiOptions(
        productInfo: IProductInfo,
        data: SilkChefworksEmbroideryDataEmbroideryInterface,
    ) {
        return data.embroidery_options
            .map((o) => {
                const optionType = this.skuTypes[o.sku];
                if (!optionType) return undefined;
                return this.transformers[optionType].fromApiData(productInfo, o, data);
            })
            .filter(o => o);
    }

    private sortOptions(options, data) {
        const order = data.embroidery_placements.global.step_order;

        return options.sort((a, b) => {
            const x = order.indexOf(a.type);
            const y = order.indexOf(b.type);
            return x - y;
        });
    }

    getOptions(
        productInfo: IProductInfo,
        data: ITransformerData,
        lineItem: SilkRestappDataCartCartItemInterface,
    ) {
        const transformedOptions = this.transformFromApiOptions(productInfo, data);
        const sortedOptions = this.sortOptions(transformedOptions, data);
        if (data.default_logo) {
            this.setDefaultLogos(sortedOptions, data.default_logo);
        } else if (data.embroideryLogosOnfile) {
            this.prePopulateLogos(sortedOptions, data.embroideryLogosOnfile, data.allowed_logo_numbers);
        }
        this.prePopulateTextStyle(sortedOptions, data.embroidery_placements.global.color_exclusions, productInfo);
        this.prePopulateFlag(sortedOptions);

        return this.restoreOptions(sortedOptions, lineItem, data);
    }

    /**
     * This will populate color, font and placement on PDP, if only one option is available to select
     * Color Exclusions is also applied if applicable.
     * based on personalization rule
     *
     * @param options
     * @param colorExclusions
     * @param productInfo
     * @private
     */
    private prePopulateTextStyle(
        options: IEmbroideryOption[],
        colorExclusions: SilkChefworksEmbroideryDataPlacementColorExclusionsInterface,
        productInfo: IProductInfo,
    ) {
        interface IFilterColorOptions {
            colorOptions: IColorOption[];
            colorExclusions: SilkChefworksEmbroideryDataPlacementColorExclusionsInterface;
            productColor: string;
        }
        const filterColorOptions = ({ colorOptions, colorExclusions, productColor }: IFilterColorOptions) => {
            const matchingColor = Object.keys(colorExclusions)
                .filter(x => productColor?.indexOf(x) === 0)[0];
            const exclusions = colorExclusions[matchingColor] || [];
            return colorOptions.filter(x => exclusions.indexOf(x.backendValue) === -1);
        };

        /* eslint-disable no-param-reassign */
        options.forEach((option) => {
            if (this.isTextOption(option)) {
                const colorOptions = filterColorOptions({
                    colorOptions: option.colorOptions,
                    colorExclusions,
                    productColor: productInfo.productColor,
                });
                if (!option.color && colorOptions.length === 1) {
                    option.color = colorOptions[0].backendValue;
                }
                if (!option.font && option.fontOptions.length === 1) {
                    option.font = option.fontOptions[0].frontendValue;
                }
                if (!option.placement && option.placementOptions.length === 1) {
                    option.placement = option.placementOptions[0].frontendValue;
                }
            }
        });
    }

    /**
     * This will populate flags on PDP, if only one flag option is available to select based on personalization rule
     * @param options
     * @private
     */
    private prePopulateFlag(
        options: IEmbroideryOption[],
    ) {
        /* eslint-disable no-param-reassign */
        options.forEach((option) => {
            if (this.isFlagsOption(option)) {
                if (option.leftRightFlagsEnabled && !option.flag1 && !option.flag2 && option.flagOptions.length === 1) {
                    option.flag1 = option.flagOptions[0].backendValue;
                    option.flag2 = option.flagOptions[0].backendValue;
                } else if (!option.flag && option.flagOptions.length === 1) {
                    option.flag = option.flagOptions[0].backendValue;
                }
            }
        });
    }

    /**
     * This will populate logos on PDP, if only one logo is available to select based on personalization rule
     * @param options
     * @param embroideryLogosOnfile
     * @param allowedLogoNumbers
     * @private
     */
    private prePopulateLogos(
        options: IEmbroideryOption[],
        embroideryLogosOnfile: LogoResponseData | null,
        allowedLogoNumbers: Array<IPRuleLogoEmb> | null,
    ) {
        if (!embroideryLogosOnfile) return;
        // Extract an array of logo numbers
        const logoNumbersArray = embroideryLogosOnfile.logo_detail.map(
            logoObj => logoObj.logo_number,
        );

        interface IOption {
            placementOptionsPerLine: {
                lineNumber: number;
                placements: IPlacementOption[];
            }[];
        }

        const getPlacementByIndex = (option: IOption, index: number): string => {
            const { placements } = option.placementOptionsPerLine[index] ?? { placements: [] };
            return placements.length === 1 ? placements[0].frontendValue : '';
        };

        /**
         * Filter the objects based on the line_number return the logo_numbers_array from the first matching object
         * @param lineNumber
         */
        const getAllowedLogoNumbersByLineNumber = (lineNumber: number): string[] | null => {
            if (!allowedLogoNumbers) return null;

            const allowedLogoNumber: IPRuleLogoEmb|undefined = allowedLogoNumbers.find(
                logoObj => logoObj.line_number === lineNumber,
            );
            if (allowedLogoNumber) {
                return allowedLogoNumber.logo_numbers_array;
            }
            return null;
        };

        const findAllowedLogoNumber = (lineNumber: number): IPRuleLogoEmb|undefined => {
            if (!allowedLogoNumbers) return undefined;
            return allowedLogoNumbers.find(
                (allowedLogoNumber: IPRuleLogoEmb) => {
                    const { line_number: ruleLineNumber } = allowedLogoNumber;
                    return ruleLineNumber === lineNumber;
                },
            );
        };

        /**
         * If allowed logos is available for specific line;
         * - Personalization Rule (PR) is applied.
         * - May be more than one logo available on file.
         * - we need to intersect logo from PR allowed logos and logos on file. Then get the first logo.
         * else if only one logo on file then we need to select first logo on file
         * else more than one logo on file nothing to select
         * @param lineNumber
         */
        const getFirstLogo = (lineNumber: number): LogoValueObject | null => {
            const allowedLogos = getAllowedLogoNumbersByLineNumber(lineNumber);
            if (!allowedLogos) {
                return null;
            }

            const foundLogoNumber: IPRuleLogoEmb|undefined = findAllowedLogoNumber(lineNumber);
            const isAccountLogoAllowed = !!foundLogoNumber && foundLogoNumber.account_logo_allowed;
            const firstLogoNumber = intersection(logoNumbersArray, allowedLogos);
            if (!isAccountLogoAllowed && firstLogoNumber.length === 1) {
                const firstLogo: LogoDetailResponseData | null = embroideryLogosOnfile.logo_detail.find(
                    logoObj => logoObj.logo_number === firstLogoNumber[0],
                ) ?? null;
                if (firstLogo) {
                    return {
                        path: embroideryLogosOnfile.logo_base_url + firstLogo.customer_id + firstLogo.logo_path,
                        value: firstLogo.logo_number,
                    };
                }
            } else if (embroideryLogosOnfile.logo_detail.length === 1) {
                const firstLogo = embroideryLogosOnfile.logo_detail[0];
                return {
                    path: embroideryLogosOnfile.logo_base_url + firstLogo.customer_id + firstLogo.logo_path,
                    value: firstLogo.logo_number,
                };
            }
            return null;
        };

        const hasNextLogosPreSelected = (
            logoOption: IOption,
            currentIndex: number,
            maxLogos: number,
        ): boolean => {
            let result = false;
            for (let i = (currentIndex + 1); i < maxLogos; i += 1) {
                const nextLogoValueObj = getFirstLogo(i + 1);
                const nextPlacementPerLine = getPlacementByIndex(logoOption, i);
                result = !!(nextLogoValueObj && nextLogoValueObj.value &&
                    nextLogoValueObj.path && nextPlacementPerLine);
                if (result) {
                    break;
                }
            }

            return result;
        };

        options.forEach((option) => { // TODO: Correct typings needed here
            if (this.isLogosOption(option)) {
                // prevent overwriting user selected logos
                if (option.logos.length) return;
                const { maxLogos } = option;
                for (let i = 0; i < Number(maxLogos); i += 1) {
                    const logoValueObj = getFirstLogo(i + 1);
                    const placementPerLine = getPlacementByIndex(option, i);

                    const isNextLogosSelected = hasNextLogosPreSelected(option, i, Number(maxLogos));

                    if (logoValueObj && logoValueObj.value && logoValueObj.path && placementPerLine) {
                        option.logos.push({
                            logo: {
                                type: 'on-file',
                                valueObj: logoValueObj,
                            },
                            placement: placementPerLine,
                            liquidPixelPlacement: '',
                            side: '',
                        });
                    } else if (isNextLogosSelected) {
                        option.logos.push({
                            logo: null,
                            placement: placementPerLine,
                            liquidPixelPlacement: '',
                            side: '',
                            isRemoved: false,
                        });
                    }
                }
            }
        });
    }

    // Type guard for ITextOption
    private isTextOption(option: IEmbroideryOption): option is ITextOption {
        return (option as ITextOption).type === 'text';
    }

    // Type guard for ILogosOption
    private isLogosOption(option: IEmbroideryOption): option is ILogosOption {
        return (option as ILogosOption).type === 'logos';
    }

    // Type guard for IFlagsOption
    private isFlagsOption(option: IEmbroideryOption): option is IFlagsOption {
        return (option as IFlagsOption).type === 'flags';
    }

    private setDefaultLogos(
        options: IEmbroideryOption[],
        defaultLogo: SilkChefworksEmbroideryDataDefaultLogoEmbroideryInterface,
    ) {
        options.forEach((option) => {
            if (this.isLogosOption(option)) {
                option.logos.push({
                    logo: {
                        type: 'on-file',
                        valueObj: {
                            path: defaultLogo.image_link,
                            value: defaultLogo.number,
                        },
                    },
                    placement: defaultLogo.default_placement,
                    liquidPixelPlacement: '',
                    side: '',
                });

                if (defaultLogo.lock) {
                    option.isLocked = true; // eslint-disable-line no-param-reassign
                }
            }
        });
    }

    private apiOptions(inputData, options, productInfo) {
        return options.flatMap(o => this.transformers[o.type].toApiData(o, inputData, productInfo));
    }

    private doEffects(inputData, options, api) {
        const effects = options
            .filter(o => this.transformers[o.type].performEffects)
            .map(o => (
                this
                    .transformers[o.type]
                    .performEffects(o, inputData, api)
            ));

        return Promise.all(effects);
    }

    async saveOptions(api, productInfo, options, item) {
        const inputData = {};
        const itemId = item?.item_id;

        await this.doEffects(inputData, options, api);

        const data = this.apiOptions(inputData, options, productInfo);
        const sourceMapping = {
            ...getSourceMapping(options[0]).productInfo,
        };

        const productId: number = sourceMapping?.id ?? 0;
        const overrideCategoryId: number|null = getPortalOverrideCategoryId(productId, item);
        let bundlePayload = {};
        if (productInfo.productType === getProductTypeBundle()) {
            bundlePayload = {
                qty: productInfo.quantity,
                bundle_option: JSON.stringify(productInfo.selections),
                bundle_option_qty: JSON.stringify(productInfo.selectionsQty),
            };
        }

        const payload = {
            ...sourceMapping,
            ...bundlePayload,
            quantity: productInfo.quantity,
            selections: productInfo.selections,
            options: data,
            overrideCategoryId,
            lpImages: productInfo.lpImages,
        };

        if (itemId) {
            return api.submitUpdate(payload, itemId);
        }

        return api.submitEmbroidery(payload);
    }

    async saveOptionsEc(api, productInfo, options, itemId) {
        const uuid = Date.now();
        const inputData = {};
        await this.doEffects(inputData, options, api);
        const data = this.apiOptions(inputData, options, productInfo);
        const payload = {
            ...getSourceMapping(options[0]).productInfo,
            itemIdUpdate: itemId,
            quantity: productInfo.quantity,
            selections: productInfo.selections,
            options: data,
            configuration: this.getLpImages(productInfo, uuid),
        };
        if (itemId) {
            api.submitLpImageEc(productInfo.lpImages, uuid);
            return api.submitUpdate(payload, itemId);
        }
        api.submitLpImageEc(productInfo.lpImages, uuid);
        return api.submitEmbroideryEc(payload);
    }

    async downloadOptions(api, productInfo, options) {
        const uuid = Date.now();
        const inputData = {};
        await this.doEffects(inputData, options, api);
        const payload = {
            ...getSourceMapping(options[0]).productInfo,
            selections: productInfo.selections,
            options,
            configuration: this.getLpImages(productInfo, uuid),
            sku: productInfo.sku,
        };
        return api.downloadEmbroidery(payload);
    }

    private getLpImages(productInfo, uuid) {
        const productNameVal = localStorage.getItem('productname');
        const description = localStorage.getItem('description');
        const entityIdVal = localStorage.getItem('entity_id');
        const itemIdVal = localStorage.getItem('item_id');
        return [
            {
                entityId: entityIdVal,
                imageId: uuid,
                productName: productNameVal,
                productDesc: description,
                cartItemId: itemIdVal,
                lpImages: productInfo.lpImages,
            }];
    }

    private restoreOptions(options, item, transformerData) {
        if (!item) return options;

        const restoreLookup = {};
        item.embroidery_items
            .filter(x => this.skuTypes[x.sku])
            .forEach((x) => {
                const optionType = this.skuTypes[x.sku];
                if (!optionType) return;
                if (optionType === 'logos') {
                    if (restoreLookup[optionType] === undefined) {
                        restoreLookup[optionType] = [];
                    }
                    restoreLookup[optionType].push(x);
                } else {
                    restoreLookup[optionType] = x;
                }
            });

        return options.map((o) => {
            const data = restoreLookup[o.type];
            if (!data) return o;
            return this.transformers[o.type].restoreData(o, data, transformerData);
        });
    }

    getEmbroideryState(items, lineItem) {
        const state = {};

        items.forEach((item) => {
            item.embroidery_items
                .forEach((x) => {
                    const optionType = this.skuTypes[x.sku];
                    const transformer = this.transformers[optionType];
                    if (!transformer) return;
                    if (!transformer.addState) return;

                    transformer.addState(state, x, lineItem);
                });
        });

        return state;
    }
}
